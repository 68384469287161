<template>
  <v-container>
    <div class="text-right mb-2 mt-5"></div>
    <div class="">
      <v-flex class="py-2 px-3 tableHeader">{{
        $vuetify.lang.t("$vuetify.automated_interview_list")
      }}</v-flex>

      <!-- <v-form
          style="width: 100%"
          class="tableSearch"
          @submit.prevent="searchData"
        >
          <v-row align="center" class="ma-0">
            <v-col cols="5">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                class="mx-2"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-select
                :items="searchable_items"
                :item-text="'text'"
                :item-value="'search'"
                v-model="search_field"
                :label="$vuetify.lang.t('$vuetify.search_from_column')"
                class="mx-2"
                disable-initial-sort
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-btn type="submit" outlined block color="secondary">{{
                $vuetify.lang.t("$vuetify.search")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-form> -->
    </div>

    <v-data-table
      :headers="headers"
      :items="formatedList"
      :loading="loading"
      :options="paginationOptions"
      :server-items-length="totalCount"
      @pagination="someChange"
      item-key="id"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1"
    >
      <template v-slot:[`item.job_title`]="{ item }">
        <!-- <span
            style="font-weight:bold;cursor: pointer;"
            @click="moveToTitle(item)"
            >{{ item.job_title }}</span
          > -->
        <router-link
          v-if="!item.is_interviewer"
          style="font-weight: bold; cursor: pointer"
          class=""
          :to="`/interview-results/${item.id}`"
        >
          {{ item.job_title }}
        </router-link>
      </template>

      <template v-slot:[`item.location`]="{ item }">
        <span>{{ formatAllLocations(item.location) }}</span>
        <v-tooltip
          top
          color="primary"
          v-if="showLocationsTooltip(item.location)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary" small class="mx-1"
              >mdi-information</v-icon
            >
          </template>
          <span>{{ showLocationsTooltip(item.location) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.hired_days`]="{ item }">
        <span class="secondary--text" v-if="!item.is_interviewer">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="success--text">{{
                item.hired.joined
              }}</span>
              /
            </template>
            <span>Filled Jobs</span>
          </v-tooltip>

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="error--text">{{
                item.hired.positions
              }}</span>
            </template>
            <span>Total Jobs</span>
          </v-tooltip>

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="ml-2">
                ({{ item.hired.days }} days)
              </span>
            </template>
            <span>Time to hire</span>
          </v-tooltip>
        </span>
        <span v-else>n/a</span>
      </template>

      <template v-slot:[`item.new_applications`]="{ item }">
        <router-link
          v-if="!item.is_interviewer"
          class=""
          :to="`/posted-jobs/new/${item.id}`"
        >
          {{ item.new_applications }}
        </router-link>
        <span v-else>n/a</span>
      </template>

      <template v-slot:[`item.total_applications`]="{ item }">
        <router-link
          v-if="!item.is_interviewer"
          class=""
          :to="`/posted-jobs/total/${item.id}`"
        >
          {{ item.total_applications }}
        </router-link>
        <span v-else>n/a</span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <template v-if="!item.is_interviewer">
          <v-btn
            icon
            outlined
            @click="makeConfirmation(item, 'delete')"
            color="secondary"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-delete </v-icon>
          </v-btn>
          <v-btn
            icon
            outlined
            @click="makeConfirmation(item, 'edit')"
            color="secondary"
            small
          >
            <v-icon small color="secondary"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-else>--</span>
      </template>

      <template v-slot:[`item.link`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on"> Share </v-btn>
          </template>
          <v-list>
            <v-list-item @click="shareSocialMedia(item, 'facebook')">
              <v-list-item-title>Facebook</v-list-item-title>
            </v-list-item>

            <v-list-item @click="shareSocialMedia(item, 'linkedin')">
              <v-list-item-title>LinkedIn</v-list-item-title>
            </v-list-item>

            <v-list-item @click="shareSocialMedia(item, 'email')">
              <v-list-item-title>Email</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-clipboard="`${item.link}?emp_id=${user._id}`"
              v-clipboard:success="clipboardSuccessHandler"
              v-clipboard:error="clipboardErrorHandler"
            >
              <v-list-item-title>Copy Link</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <template v-if="false">
          <v-btn
            icon
            outlined
            color="secondary"
            @click="shareSocialMedia(item, 'facebook')"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-facebook </v-icon>
          </v-btn>

          <v-btn
            icon
            outlined
            color="secondary"
            @click="shareSocialMedia(item, 'linkedin')"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-linkedin </v-icon>
          </v-btn>

          <v-btn
            icon
            outlined
            color="secondary"
            @click="shareSocialMedia(item, 'email')"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-email </v-icon>
          </v-btn>

          <v-btn
            icon
            outlined
            color="secondary"
            v-clipboard="`${item.link}?emp_id=${user._id}`"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            small
          >
            <v-icon small color="secondary"> mdi-clipboard-text </v-icon>
          </v-btn>
        </template>
      </template>
    </v-data-table>

    <!-- confirmationhandler -->
    <v-dialog v-model="showConfirmationModal" max-width="500px">
      <v-card class="pa-2">
        <v-card-title
          ><span class="headline">
            {{
              $vuetify.lang.t(
                `$vuetify.popup.posted_job.${
                  modelType === "delete" ? "delete_job" : "edit"
                }`
              )
            }}
          </span></v-card-title
        >
        <v-card-actions class="text-right">
          <v-btn
            color="secondary"
            class="mx-3"
            outlined
            @click="showConfirmationModal = false"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>

          <v-btn
            @click="confirmHandler"
            :loading="btnLoading"
            v-if="modelType"
            color="secondary"
            class="mx-0 elevation-0"
          >
            {{ $vuetify.lang.t(`$vuetify.${modelType}`) }}
            {{ $vuetify.lang.t("$vuetify.job") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- invite candidate by email -->
    <v-dialog v-model="invitecandidate" max-width="600px">
      <v-card>
        <v-card-text class="pa-3 py-4">
          <template>
            <div xs12 style="width: 100%">
              <h3>
                {{
                  $vuetify.lang.t(
                    "$vuetify.popup.social_share.invite_candidate"
                  )
                }}
              </h3>
              <p>
                {{ $vuetify.lang.t("$vuetify.popup.social_share.example") }}
                <b>xyz@gmail.com,abc@gmail.com </b>
              </p>
              <v-form
                v-on:submit.prevent="sendCandidateInvitation"
                xs12
                style="width: 100%"
              >
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.candidate_emails')"
                  required
                  class="mr-2"
                  v-model="emails"
                  :rules="fieldRules"
                >
                </v-text-field>

                <v-flex class="text-sm-center my-3">
                  <v-btn
                    type="submit"
                    class="mr-2"
                    :loading="btnLoading"
                    color="secondary"
                  >
                    {{ $vuetify.lang.t("$vuetify.save_changes") }}
                  </v-btn>
                  <v-btn
                    outlined
                    @click="invitecandidate = false"
                    class="buttonPrimary text-lightblue close-modal"
                  >
                    {{ $vuetify.lang.t("$vuetify.cancel") }}
                  </v-btn>
                </v-flex>
              </v-form>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- cannot delete post -->
    <v-dialog v-model="cannotDeleteModal" max-width="500px">
      <v-card class="pa-2">
        <v-card-title
          ><span class="headline">
            You cannot delete this job post because it has more then one profile
          </span></v-card-title
        >
        <v-card-actions class="text-right">
          <v-btn
            color="secondary"
            class="mx-3"
            outlined
            @click="cannotDeleteModal = false"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar
      :timeout="2000"
      v-model="snackbar"
      :color="isError ? 'error' : 'success'"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
//   import { UTC_TO_LOCAL } from "@/helpers";

import userRestriction from "@/mixins/user-restriction.js";
export default {
  name: "interview-results",
  mixins: [userRestriction],
  data() {
    return {
      loading: false,
      items: [],
      search_field: "",
      search: "",
      //   pagination
      totalCount: 0,
      currentPage: 1,
      limit: 10,
      //   snackbar
      snackbar: false,
      snackbarText: "",
      isError: false,
      //   shareByEmail
      shareJobPost: {
        id: "",
        title: "",
      },
      invitecandidate: false,
      emails: "",
      fieldRules: [(v) => !!v || "Field is required"],
      btnLoading: false,
      activeJob: null,
      showConfirmationModal: false,
      modelType: "",
      cannotDeleteModal: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    headers() {
      let arr = [
        {
          text: this.$vuetify.lang.t("$vuetify.job_post.job_title"),
          value: "job_title",
          showAll: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.posted_date"),
          value: "posted_date",
          showAll: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.closing_date"),
          value: "closing_date",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.interviews_requested"),
          value: "total_applicants",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.attempted"),
          value: "test_attempted_candidates",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status"),
          value: "status",
          showAll: true,
        },
      ];

      return arr.filter(() => {
        // if (this.isAdmin) return true;
        // return a.showAll;
        return true;
      });
    },
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    searchable_items() {
      return [
        {
          search: "title",
          text: "Post",
        },
      ];
    },
    whereHas() {
      if (this.search_field && this.search) {
        return JSON.stringify({
          key: this.search_field,
          value: this.search,
        });
      } else {
        return false;
      }
    },
    formatedList() {
      return this.items;
    },
  },
  methods: {
    someChange(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchList();
      }
    },
    searchData() {
      this.currentPage = 1;
      this.fetchList();
    },
    async fetchList() {
      let interviewList = [
        {
          id: 1001121789432,
          job_title: "GoLang Developer",
          posted_date: "Oct 1, 2022",
          closing_date: "Oct 13, 2022",
          total_applicants: 12,
          test_attempted_candidates: 5,
          status: "Closed",
        },
        {
          id: 1143657687686,
          job_title: "Vue Developer",
          posted_date: "Sep 21, 2022",
          closing_date: "Oct 23, 2022",
          total_applicants: 8,
          test_attempted_candidates: 3,
          status: "Closed",
        },
        {
          id: 1147358937012,
          job_title: "NodeJS Developer",
          posted_date: "Sep 30, 2022",
          closing_date: "",
          total_applicants: 13,
          test_attempted_candidates: 9,
          status: "Active",
        },
        {
          id: 1134309039444,
          job_title: "NustJS Developer",
          posted_date: "Aug 11, 2022",
          closing_date: "Oct 19, 2022",
          total_applicants: 25,
          test_attempted_candidates: 13,
          status: "Closed",
        },
        {
          id: 1165677657657,
          job_title: "NextJS Developer",
          posted_date: "Oct 13, 2022",
          closing_date: "",
          total_applicants: 10,
          test_attempted_candidates: 5,
          status: "Active",
        },
        {
          id: 6789043783843,
          job_title: "WordPress Developer",
          posted_date: "Oct 11, 2022",
          closing_date: "Oct 13, 2022",
          total_applicants: 12,
          test_attempted_candidates: 5,
          status: "Closed",
        },
        {
          id: 4323526352872,
          job_title: "Jhoomla Developer",
          posted_date: "Sep 26, 2022",
          closing_date: "Oct 23, 2022",
          total_applicants: 8,
          test_attempted_candidates: 3,
          status: "Closed",
        },
        {
          id: 8283821832323,
          job_title: "Drupal Developer",
          posted_date: "Sep 10, 2022",
          closing_date: "",
          total_applicants: 13,
          test_attempted_candidates: 9,
          status: "Active",
        },
        {
          id: 9089889787887,
          job_title: "ROR Developer",
          posted_date: "Aug 21, 2022",
          closing_date: "Oct 19, 2022",
          total_applicants: 25,
          test_attempted_candidates: 13,
          status: "Closed",
        },
        {
          id: 3232323323243,
          job_title: "Scala Developer",
          posted_date: "Oct 23, 2022",
          closing_date: "",
          total_applicants: 10,
          test_attempted_candidates: 5,
          status: "Active",
        },
      ];
      this.totalCount = interviewList.length;
      this.items = interviewList;
      this.loading = false;
    },
    shareSocialMedia(job, platform) {
      if (platform === "facebook") {
        let link = `https://www.facebook.com/sharer/sharer.php?u=${job.link}?emp_id=${this.user._id}&platform=social_media`;
        window.open(link, "_blank");
      } else if (platform === "linkedin") {
        let link = `https://www.linkedin.com/sharing/share-offsite/?url=${job.link}?emp_id=${this.user._id}&platform=social_media`;
        window.open(link, "_blank");
      } else {
        this.emails = null;
        this.invitecandidate = true;
        this.shareJobPost.id = job.id;
        this.shareJobPost.title = job.title;
      }
    },
    clipboardSuccessHandler() {
      this.isError = false;
      this.snackbar = true;
      this.snackbarText = "Link Copied Successfully.";
    },
    clipboardErrorHandler() {
      this.isError = true;
      this.snackbar = true;
      this.snackbarText = "SOmething Went wrong while copying the link";
    },
    async sendCandidateInvitation() {
      try {
        if (!this.emails) return;

        this.btnLoading = true;
        const link = `${this.user.company.url}/job/${this.shareJobPost.id}?emp_id=${this.user._id}&platform=email`;
        await this.axios.post("/invite/candidate", {
          emails: this.emails,
          job: this.shareJobPost.title,
          link,
        });

        this.btnLoading = false;
        this.invitecandidate = false;
        this.emails = null;
        this.shareJobPost = { title: "", id: "" };
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    makeConfirmation(job, type) {
      if (type === "delete") {
        const { total_applications } = job;
        if (total_applications > 0) return (this.cannotDeleteModal = true);
      }
      this.activeJob = job;
      this.modelType = type;
      this.showConfirmationModal = true;
    },
    confirmHandler() {
      if (this.modelType === "edit") {
        this.editJob();
      } else {
        this.deleteJob();
      }
    },
    editJob() {
      window.open(`#/job-post/${this.activeJob.id}`, "_blank");
      this.activeJob = null;
      this.modelType = "";
      this.showConfirmationModal = false;
    },
    async deleteJob() {
      try {
        this.btnLoading = true;
        await this.axios.delete(`/job/${this.activeJob.id}`);
        this.fetchList();
        this.btnLoading = false;
        this.activeJob = null;
        this.modelType = "";
        this.showConfirmationModal = false;
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    formatAllLocations(locations) {
      if (locations) {
        if (typeof locations === "string") {
          let locs = locations.split(",");
          if (locs.length > 2) return locs[0].trim() + ", " + locs[1].trim();
          return locations;
        }

        if (Array.isArray(locations)) {
          let locs = locations;
          if (locs.length > 2) return locs[0].trim() + ", " + locs[1].trim();
          return locations.join(", ");
        }
      }

      return locations;
    },
    showLocationsTooltip(locations) {
      if (locations) {
        if (typeof locations === "string") {
          let locs = locations.split(", ");
          if (locs.length > 2)
            return locs.splice(2, locs.length - 1).join(", ");
        }

        if (Array.isArray(locations)) {
          let locs = locations;
          if (locs.length > 2)
            return locs.splice(2, locs.length - 1).join(", ");
        }
      }
      return false;
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
